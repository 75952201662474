<template>
  <div class="d-flex align-center">
    <v-chip
        class="manager-status"
        :class="chipColor"
    >
      Manager {{pSelectedTeam.getDisplayManagerState()}}
      <span
          class="ml-1"
          v-if="pManager"
      >(<router-link
          class="manager-name"
          :class="chipColor"
          :to="`/profiles/${pManager.id}`"
      >{{pManager.fullName}}</router-link>)
          </span>
    </v-chip>
  </div>
</template>

<script>
import {
  TEAM_MANAGER_STATE_ACCEPTED,
  TEAM_MANAGER_STATE_CANCELED,
  TEAM_MANAGER_STATE_DECLINED,
  TEAM_MANAGER_STATE_DRAFT,
  TEAM_MANAGER_STATE_FINAL_INVITE,
  TEAM_MANAGER_STATE_INITIAL_INVITE,
  TEAM_MANAGER_STATE_EXPIRED,
  TEAM_MANAGER_STATE_NOMINATED,
} from "@/utils/utils";

export default {
  name: "TeamManagerStateComponent",

  props: {
    pSelectedTeam: Object,
    pManager: Object,
  },

  computed: {
    chipColor() {
      const state = this.pSelectedTeam.managerApprovalState;

      if (state === TEAM_MANAGER_STATE_ACCEPTED.value || this.pSelectedTeam.managerViewerUser) return ["green", "white--text"];

      if (state === TEAM_MANAGER_STATE_CANCELED.value) return ["red", "white--text"];

      if (state === TEAM_MANAGER_STATE_DECLINED.value) return ["red", "white--text"];

      if (state === TEAM_MANAGER_STATE_DRAFT.value) return ["grey", "white--text"];

      if (state === TEAM_MANAGER_STATE_FINAL_INVITE.value) return "yellow";

      if (state === TEAM_MANAGER_STATE_INITIAL_INVITE.value) return "yellow";

      if (state === TEAM_MANAGER_STATE_NOMINATED.value) return ["blue", "white--text"];

      if (state === TEAM_MANAGER_STATE_EXPIRED.value) return ["red", "white--text"];

      return "";
    },
  },
};
</script>
